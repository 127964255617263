import "base/assets/fonts/ToyotaType-Light.ttf";
import "base/assets/fonts/ToyotaType-Regular.ttf";
import "base/assets/fonts/ToyotaType-Semibold.ttf";
import "flag-icons/css/flag-icons.min.css";
import ReactDOM from "react-dom/client";
import BookingBarApp from "./app/booking-bar";
import BookingEngineApp from "./app/booking-engine";
import "./index.scss";

const development = process.env.NODE_ENV === "development";

// NEED TO CHANGE TO SUITABLE COMPONENT APP FOR DEVELOPING
const developingComponent = {
  "booking-bar": BookingBarApp,
  "booking-engine": BookingEngineApp,
};

const productionComponents = {
  "booking-bar": BookingBarApp,
  "booking-engine": BookingEngineApp,
};

const components: any = development
  ? developingComponent
  : productionComponents;

(() => {
  for (const key of Object.keys(components)) {
    const element = document.getElementById(key);
    if (element) {
      const root = ReactDOM.createRoot(element);
      const Component = components[key];
      root.render(<Component />);
    }
  }
})();
